$(function(){

    var init = false;

    $('.slideSet').flickity({
        // options
        cellAlign: 'left',               
        wrapAround: true,    
        groupCells: "100%",    
        contain: true,
        pageDots: true,
        prevNextButtons: true,  
        imagesLoaded:true,
        adaptiveHeight: true,
    });

    if($('.groupSlider').length > 0)
	{
        $('.groupSlider').flickity({
            cellAlign: 'center',
            wrapAround: false,
            groupCells: "true",
            contain: true,
            pageDots: true,
            prevNextButtons: true,
            imagesLoaded: true,
            adaptiveHeight: true,
        });
    };

    if($('.groupLogoSlider').length > 0)
    {
        $('.groupLogoSlider').flickity({
            cellAlign: 'center',
            wrapAround: false,
            //groupCells: 'true',
            contain: true,
            pageDots: false,
            prevNextButtons: false,
            imagesLoaded: true,
            adaptiveHeight: true,
            autoPlay: 1000,
            pauseAutoPlayOnHover: false
        });
    };
});












